import React from "react";

const TravelReason = ({ icon, title, description }) => {
  return (
    <div className="reason">
      <img src={icon} alt="Icon" className="travel-card-icon" />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default TravelReason;
