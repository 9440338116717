import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableNews } from "../servicies/news-service";
import { dummyNewsData } from "../../constants/data";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const newsSlice = createSlice({
  name: "allNewsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableNews.pending, (state) => {
        return { ...state, data: null, isLoading: true, error: null };
      })
      .addCase(fetchAvailableNews.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAvailableNews.rejected, (state, action) => {
        return {
          ...state,
          data: dummyNewsData,
          isLoading: false,
          error: null,
        };
      });
  },
});

export default newsSlice.reducer;
