import React from "react";

const PriceHeader = () => {
    return (
        <div>
            <div className="price-header-container">
               <span>Ruta</span>
               <span>Preț tur</span>
               <span>Preț retur</span>
               <span>Preț tur- retur</span>
            </div>
        </div>
    );
};

export default PriceHeader;