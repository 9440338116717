const CheckTiket = () => {
  return (
    <div className="c-about">
      <div className="c-content d-flex">
        <div className="col-5"></div>
        <div className="col-7 px-4">
          <p>verifica bilet</p>
        </div>
      </div>
    </div>
  );
};

export default CheckTiket;
