import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableLocations } from "../servicies/locations-service";
import { dummyLocationsData } from "../../constants/data";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const locationsSlice = createSlice({
  name: "allLocationsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableLocations.pending, (state) => {
        return { ...state, data: null, isLoading: true, error: null };
      })
      .addCase(fetchAvailableLocations.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAvailableLocations.rejected, (state, action) => {
        return {
          ...state,
          data: dummyLocationsData,
          isLoading: false,
          error: null,
        };
      });
  },
});

export default locationsSlice.reducer;
