import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const fetchAvailableParcels = createAsyncThunk(
  "etchAvailableParcels",
  async () => {
    const response = await api.get("destinations/parcels");
    return response.data;
  }
);
