import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const fetchPrice = createAsyncThunk("fetchPrice", async (trip) => {
  const response = await api.get(
    "destinations/trip?type=" +
      trip.type +
      "&departure=" +
      trip.departure +
      "&destination=" +
      trip.destination +
      "&adults=" +
      trip.adults +
      "&children=" +
      trip.children +
      "&departureDate=" +
      trip.departureDate +
      "&returnDate=" +
      trip.returnDate
  );
  return response.data;
});
