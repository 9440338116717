import { useEffect } from "react";
import OtherNews from "../components/other-news-card"
import NewsDetailsCard from "../components/news-details-card";
import { fetchAvailableNews } from "../redux/servicies/news-service";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import Loader from "../components/loader";

const NewsDetails = () => {
  const { data, isLoading, error } = useAppSelector((state) => state.allNewsReducer);
  const dispatch = useAppDispatch();

  const url = window.location.href;
  const urlParams = new URL(url);
  const newsId = parseInt(urlParams.searchParams.get('id'), 10);

  useEffect(() => {
    dispatch(fetchAvailableNews());
  }, [dispatch]);


  return (
    <div className="news-details-container">
      <div className="news-details-body">
        {isLoading && <div style={{ justifyContent: "center", display: 'flex' }}><Loader /></div>}
        {error && <div style={{ color: "red", justifyContent: "center", display: 'flex' }}>Datele nu au putut fi încărcate. Vă rugăm să încercați din nou mai târziu</div>}
        {!isLoading && !error && (
          <div className="news-details-grid">
            <div>
              {data && (<NewsDetailsCard
                news={data?.find(item => item.id === newsId)}
              />)
              }
            </div>
            <div className="news-details-others-grid">
              <h2>Alte articole</h2>
              {data?.filter(item => item.id !== newsId)?.map((data) => (
                <div>
                  <OtherNews
                    news={data}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsDetails;