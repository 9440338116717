import { useState } from 'react';
import SwitchTab from '../components/switch-tab';
import RentalCard from '../components/rental-card';
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import Loader from "../components/loader";
import { fetchAvailableBus } from '../redux/servicies/bus-service';

const Rent = () => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useAppSelector((state) => state.allBusesReducer);
  useEffect(() => {
    dispatch(fetchAvailableBus("bus"));
  }, [dispatch]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    dispatch(fetchAvailableBus(tab === 1 ? "bus" : "microbus"));
  };

  return (
    <div className="rentals-container">
      <div className="rentals-body">
        
        <h2>Închirieri Autocare și Microbuze</h2>
        <p>
        Siguranța și confortul călătorilor sunt prioritatea noastră cu o flotă modernă, echipată cu cele mai noi sisteme de siguranță.
        </p>
        <div className="switch-container">
          <SwitchTab
            activeTab={activeTab}
            onTabChange={handleTabChange}
            tab1="Autocare"
            tab2="Microbuze"
          />
        </div>
        {isLoading && <div style={{ justifyContent: "center", display: 'flex', marginTop: "10px" }}><Loader /></div>}
        {error && <div style={{ color: "red", justifyContent: "center", display: 'flex' }}>Datele nu au putut fi încărcate. Vă rugăm să încercați din nou mai târziu</div>}
        {!isLoading && !error && (
           <div className="rentals-grid">
           {
            data?.map((rentalItem) =>
               <div>
                 <RentalCard
                   rental={rentalItem}
                 />
               </div>
             )
           }
         </div>
          )}
      </div>
    </div>
  );
};

export default Rent;
