import axios from "axios";

const instance = axios.create({
  baseURL: "https://euro-fratello.ro:9000/api/",
  headers: {
    "X-API-KEY": process.env.REACT_APP_X_API_KEY,
    "X-API-SECRET": process.env.REACT_APP_X_API_SECRET,
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
