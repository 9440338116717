import React from "react";

const NewsMainCard = ({ image, title, description, tag, readMoreLink }) => (
    <div className="news-info-card-main">
        <div className="news-image-container">
        <img src={image} alt="Card Banner" className="news-card-image-main" />
        <div className="news-tag">
            <span className="news-tag-text">{tag}</span>
        </div>
        </div>
            <div className="news-card-text">
                <h2 className="news-card-title-main">{title}</h2>
                <p className="news-card-description-main">{description}</p>
            </div>
        <div>
                <a href={readMoreLink} className="news-read-more">
                    Citește mai mult →
                </a>
            </div>
    </div>
);

export default NewsMainCard;