import React from "react";
import PhoneCard from "../components/phone"
const LocationsCard = ({ location }) => (
    <div className="locations-card-body">
        <div className="locations-text">
            <h2> {location.cityName} </h2>
            <span>{location.address}</span>
        </div>
        <div className="phones-grid">
            {
                location.phoneNumbers
                    .filter((item) => item.trim() !== '')
                    .map((phone) =>
                        <div>
                            <PhoneCard
                                phoneNumber={phone}
                            />

                        </div>
                    )
            }

        </div>
    </div>
);

export default LocationsCard;