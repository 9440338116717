import React from "react";

const NewsCard = ({ image, title, description, timestamp, readMoreLink }) => {
  const datePart = timestamp.split('T')[0];

  return (
    <div className="news-info-card">
      <img src={image} alt="Card Banner" className="news-card-image" />
      <h2 className="news-card-title">{title}</h2>
      <p className="news-card-description">{description}</p>
      <div className="news-card-footer">
        <span className="news-timestamp">{datePart}</span> {/* Display only the date part */}
        <a href={readMoreLink} className="news-read-more">
          Citește mai mult →
        </a>
      </div>
    </div>
  );
}

export default NewsCard;
