import React from "react";
import separator from "../assets/separator.svg";
import agentii from "../assets/agentiiHeader.svg";
import news from "../assets/noutatiHeader.svg";
import colete from "../assets/coleteHeader.svg";
import contact from "../assets/contactHeader.svg";
import inchirieri from "../assets/inchirieriHeader.svg";
import destinatii from "../assets/destinatiiHeader.svg";
import { useLocation } from 'react-router-dom';
const Header = () => {
    const location = useLocation();
    const url = window.location.href;
    const urlParams = new URL(url);
    const countryName = urlParams.searchParams.get('name');
    switch (location.pathname.trim()) {
        case '/': return (<></>)
        case '/detalii-bilet': return (<></>)
        case '/plateste': return (<></>)
        case '/finalizare-plata': return (<></>)
        case '/informatii': return (<></>)
        case "/agentii-romania":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={agentii} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Agenții Euro Fratello</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Agenții România</span>
                        </div>
                    </div>
                </div>
            )
        case "/agentii-internationale":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={agentii} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Agenții Euro Fratello</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Agenții Internaționale</span>
                        </div>
                    </div>
                </div>
            )
        case "/detalii-noutati":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={news} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Noutăți și Articole</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Noutăți</span>
                        </div>
                    </div>
                </div>
            )
        case "/inchirieri":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={inchirieri} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Alegeți-vă autocarul</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Închirieri</span>
                        </div>
                    </div>
                </div>
            )
        case "/colete":
            return (
                <div className="header-container-colete">
                    <div class="image-container">
                        <img src={colete} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>
                    <div className="header-body">
                        <div className="header-tag">
                            <h2 className="title-text">Serviciu de expediere colete</h2>
                            <div className="description-container">
                                <span className="description-text">Pagina principală</span>
                                <img src={separator} alt="Icon" className="separator-icon" />
                                <span className="description-text">Colete</span>
                            </div>
                        </div>

                        <div className="header-details">
                            <div className="details-body">
                                <h2 className="details-text">Pentru mai multe detalii suna la </h2>
                                <div className="phone-container">
                                    <h2 className="details-text">0230 221 297 </h2>
                                </div>
                            </div>

                            <div className="schedule-container">
                                <h2 className="details-text">Program Preluări/Livrări</h2>
                                <h2 className="details-text">08:00 - 16:00 - LUNI-VINERI</h2>
                                <h2 className="details-text">08:00 - 12:00 - SÂMBĂTA</h2>
                            </div>

                        </div>
                    </div>
                </div>
            )
        case "/noutati":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={news} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Noutăți și Articole</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Noutăți</span>
                        </div>
                    </div>
                </div>
            )
        case "/contact":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={contact} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Contactați-ne</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Contact</span>
                        </div>
                    </div>
                </div>
            )
        case "/verifica-bilet":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={"https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg"} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Agenții Euro Fratello</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Agenții Internaționale</span>
                        </div>
                    </div>
                </div>
            )

        case "/destinatie":
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={destinatii} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Transport persoane</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Transport <span className="description-text">{countryName} </span> </span>
                        </div>
                    </div>
                </div>
            )

        default:
            return (
                <div className="header-container">
                    <div class="image-container">
                        <img src={"https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg"} alt="Icon" className="header-image" />
                        <div class="overlay"></div>
                    </div>

                    <div className="header-tag">
                        <h2 className="title-text">Agenții Euro Fratello</h2>
                        <div className="description-container">
                            <span className="description-text">Pagina principală</span>
                            <img src={separator} alt="Icon" className="separator-icon" />
                            <span className="description-text">Agenții Internaționale</span>
                        </div>
                    </div>
                </div>
            )

    }
};

export default Header;
