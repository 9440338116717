import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Phone from "./phone";

const ModalComponent = ({ show, handleClose }) => {

  return (
    <Modal className="bus-rides-container" show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Curse la adresă</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Pentru curse direct la adresă vă rugăm să ne contactați telefonic:</span>
        <div style={{alignContent:"center"}}>
          <br></br>
          <br></br>
          <Phone phoneNumber={"+40 724 122 272"}/>
          <br></br>
          <Phone phoneNumber={"+40 741 258 455"}/>
          <br></br>
          <Phone phoneNumber={"+40 741 258 456"}/>
          <br></br>
          <Phone phoneNumber={"+40 756 086 166"}/>
          <br></br>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
