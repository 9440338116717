import React from "react";
import { useNavigate } from 'react-router-dom';

const OtherNews = ({ news }) => {
  let navigate = useNavigate();

  const datePart = news.publishDate.split('T')[0];

  const redirectToDetails = () => {
    navigate(`/detalii-noutati?id=${news.id}`);
    window.location.reload();
  }

  return (
    <div
      onClick={redirectToDetails}
      className="other-news-card">
      <img src={news.image} alt="Card Banner" className="other-news-image" />
      <div className="other-news-text-container">
        <h2>{news.title}</h2>
        <span>
          {datePart}
          <span>{news.tag}</span>
        </span>
      </div>
    </div>
  )
};

export default OtherNews;
