import React from "react";
import image from "../assets/bus.svg";

const RentalCard = ({ rental }) => (
    <div className="rental-card">
        <div>
            <img src={rental.imageUrl} alt="Card Banner" className="rental-image" />
        </div>
        <div className="renatl-info-body">

            <h2>{rental.name}</h2>
            <ul>
                <li>
                    An: <span>{rental.year}</span>
                </li>
                <li>
                    Locuri: <span>{rental.seats}</span>
                </li>
                <li>
                    Tarif intern/extern: <span>Contactați-ne pentru detalii</span>
                </li>
                <li>
                    Caracteristici: <span>{rental.options}</span>
                </li>
            </ul>
            <p> {rental.description} </p>
        </div>
    </div>
);

export default RentalCard;