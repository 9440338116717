import ProgressBar from "../components/progres-bar-container";
import TicketInfo from "../components/ticket-info";
import TicketInfoReview from "../components/ticket-info-review";
import { Navigate, Link, useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from "../redux/store/store";
import { createBooking } from "../redux/servicies/travel-service";
import React, { useState, useEffect } from "react";

const BuyTicketPage2 = () => {
    const location = useLocation();
    const ticket = location.state.ticket
    const passengers = location.state.passengers
    const invoice = location.state.invoice
    const departureId = location.state.departureId
    const toId = location.state.toId
    const extra = location.state.extra
    const price = ticket.totalPrice;
    const [error, setError] = useState("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const returnMessage =
        ticket.type !== 'oneWay'
            ? 'Vă rugăm să confirmați telefonic returul cu 2-3 zile înainte de plecare. '
            : '';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    function separaPasageri(passagers, adultsNo) {
        let adults = [];
        let children = [];

        for (let i = 0; i < passagers.length; i++) {
            if (i < adultsNo) {
                adults.push(passagers[i]);
            } else {
                children.push(passagers[i]);
            }
        }

        return { adults, children };
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const passagersArray = separaPasageri(passengers, ticket.adultNo, ticket.childrenNo);
        let data = {
            "adults": passagersArray.adults,
            "children": passagersArray.children,
            "type": ticket.type,
            "trip": {
                "departure": departureId,
                "destination": toId,
                "date": ticket.oneWay.date,
                "returnDate": ticket.returnTrip == null ? null : ticket.returnTrip.date
            },
            "cnp": invoice.cnp,
            "price": ticket.totalPrice,
            "email": invoice.email.toLowerCase(),
            "phoneNo": invoice.phone,
            "firstName": invoice.name,
            "lastName": invoice.surname,
            "tripDetails": extra.details
        }

        dispatch(createBooking(data))
            .unwrap()
            .then((data) => {
                setError("")
                const codeTicket = data.uniqueCode
                const email = data.email
                const url = `https://admin.euro-fratello.ro/plataOnline/${codeTicket}/${email}`;
                window.open(url, '_top');
                navigate('/', { replace: true });
            })
            .catch((error) => {
                setError("Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu.")
                console.log(error)
            });
    }

    return (
        <div className="buy-ticket-2-container">
            <div className="progress-bar-container">
                <ProgressBar
                    isLastStep={true}
                    isFinished={false}
                />
            </div>
            <div className="buy-ticket-body">
                <div className="inputs-body">
                    <TicketInfoReview
                        passengers={passengers}
                        invoice={invoice}
                        extra={extra}
                    />
                </div>
                <div className="info-body">

                    <TicketInfo
                        ticket={ticket}
                        isTwoWay={false}
                    />
                    {ticket.type != "oneWay" ?
                        <div><TicketInfo
                            ticket={ticket}
                            isTwoWay={true}
                        /></div> : <div> <></> </div>}
                    <div className="price-container">
                        <span className="price-text">Total</span>
                        <span className="price-text">{price} €<span className="price-text"> ({ticket.ronTotalPrice} lei)</span></span>
                    </div>
                    <div className="info-message">
                        <span className="bold-text">
                            <span className="orange-text">ATENȚIE:</span>{' '}
                            {returnMessage}
                            Ora de plecare este estimativă.
                        </span>
                    </div>
                    {error && (
                        <div className="red-text">
                            * {error}
                        </div>
                    )}

                    <div className="button-container">
                        <button
                            onClick={handleSubmit}
                            className="send-button">
                            Plătește acum →
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyTicketPage2;
