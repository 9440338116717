import React from "react";
import step3Selected from "../assets/step3Selected.svg";
import step4 from "../assets/step4.svg";
import step4Selected from "../assets/step4Selected.svg";
import isSelected from "../assets/isFinished.svg";


const ProgressBar = ({isLastStep, isFinished}) => {
    if (isLastStep) {
        return (
            <div className="progress-bar-container">
                <div className="orange-line"></div>
                <img src={isSelected} alt="Icon" className="progress-icon" />
                <div className="orange-line"></div>
                <img src={isSelected} alt="Icon" className="progress-icon" />
                <div className="orange-line"></div>
                <img src={isSelected} alt="Icon" className="progress-icon" />
                <div className="orange-line"></div>
                <img src={step4Selected} alt="Icon" className="progress-icon-selected" />
                <div className="dark-line"></div>
            </div>
        );
    } else {
        if (isFinished) {
            return (
                <div className="progress-bar-container">
                    <div className="orange-line"></div>
                    <img src={isSelected} alt="Icon" className="progress-icon" />
                    <div className="orange-line"></div>
                    <img src={isSelected} alt="Icon" className="progress-icon" />
                    <div className="orange-line"></div>
                    <img src={isSelected} alt="Icon" className="progress-icon" />
                    <div className="orange-line"></div>
                    <img src={isSelected} alt="Icon" className="progress-icon" />
                    <div className="orange-line"></div>
                </div>
            );
        } else {
            return (
                <div className="progress-bar-container">
                    <div className="orange-line"></div>
                    <img src={isSelected} alt="Icon" className="progress-icon" />
                    <div className="orange-line"></div>
                    <img src={isSelected} alt="Icon" className="progress-icon" />
                    <div className="orange-line"></div>
                    <img src={step3Selected} alt="Icon" className="progress-icon-selected" />
                    <div className="dark-line"></div>
                    <img src={step4} alt="Icon" className="progress-icon" />
                    <div className="dark-line"></div>
                </div>
            );
        }
    }
};

export default ProgressBar;