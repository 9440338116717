import React from "react";
import calendar from "../assets/calendarLogo.svg";
import time from "../assets/time.svg";
import tagIcon from "../assets/tag.svg";

const NewsDetailsCard = ({ news }) => {
  const datePart = news.publishDate.split('T')[0];

  return (
    <div className="news-details-card">
      <img src={news.image} alt="Card Banner" className="news-details-card-image" />

      <div className="news-details-card-container">
        <div className="news-details-tag">
          <img src={calendar} alt="Calendar Icon" className="news-details-tag-image" />
          <span>
            {datePart}
          </span>
        </div>
        <div className="news-details-tag">
          <img src={time} alt="Clock Icon" className="news-details-tag-image" />
          <span>
            {news.readingTime} minute de citit
          </span>
        </div>
        <div className="news-details-tag">
          <img src={tagIcon} alt="Tag Icon" className="news-details-tag-image" />
          <span>
            {news.tags}
          </span>
        </div>
      </div>
      <hr className="line"></hr>
      <h2>{news.title}</h2>
      <p>{news.content}</p>
    </div>
  );
}

export default NewsDetailsCard;
