export const Text = {
  CONTACT: "CONTACT",
};

export const SocialMedia = {
  Facebook: "https://www.facebook.com/EuroFratell0",
  Gmail: "mailto:contact@euro-fratello.ro",
  Twitter:
    "https://twitter.com/home?status=https://www.euro-fratello.ro/blog/preluam-colete-pentru-austria-germania-belgia-anglia-si-scandinavia",
};

export const RegexValidation = {
  EMAIL_REGEX: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  PHONE_REGEX: /^[+]?\d{1,}[ -]?\d+$/,
};
