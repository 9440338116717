import ContactUsForm from "../components/contact-us-form-card";
import ContactInfo from "../components/conatct-info-card";
const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-body">
        <h2>
          Despre Euro Fratello
        </h2>
        <p>
          Domeniul transporturilor a cunoscut o dezvoltare semnificativă în ultimii ani, iar Euro Fratello a depășit așteptările clienților săi oferind servicii de transport de persoane și colete printr-o flotă auto modernă și performantă. Autocarele și microbuzele utilizate pentru cursele tur-retur către numeroase destinații din Europa sunt supuse unor riguroase verificări tehnice și sunt echipate cu toate facilitățile necesare pentru a asigura o călătorie plăcută.
        </p>
        <p>
          Obiectivele echipei Euro Fratello vizează îmbunătățirea constantă a serviciilor de transport, fiind mereu la curent cu ultimele noutăți din acest sector.
        </p>
        <p>
          Promisiunile noastre includ confort maxim, siguranță deplină, profesionalism și asigurarea unor experiențe relaxante pentru clienții noștri. Soferii noștri sunt cunoscuți pentru corectitudinea lor în trafic, atenția sporită în condiții meteorologice nefavorabile și atenția acordată nevoilor pasagerilor. Toate aceste aspecte ne plasează în topul firmelor de transport de persoane, colete și închirieri auto. Fidelitatea clienților noștri este rezultatul promptitudinii și calității serviciilor noastre, dar și al prețurilor accesibile pe care le oferim.
        </p>
        <div className="contact-info-grid">
          <div className="contact-form">
            <ContactUsForm />
          </div>

          <div className="contact-info">
            <ContactInfo />
          </div>
        </div>
        <div className="maps-container">
          <iframe
            class="w-100"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2688.2235635749066!2d26.25703537639488!3d47.64122227119299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4734fc314d06bf1b%3A0x47645654add3ddf9!2sStrada%20Ana%20Ip%C4%83tescu%205%2C%20Suceava%20720026!5e0!3m2!1sro!2sro!4v1705090201617!5m2!1sro!2sro"
            aria-hidden="false"
            style={{
              width: "100%",
              height: "100%"
            }}
            tabindex="0"></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
