export const Nav = {
  ACASA: "Acasă",
  AGENTII: "Agenții",
  AGENTII_ROMANIA: "Agenții România",
  AGENTII_INTERNATIONALE: "Agenții Internaționale",
  COLETE: "Colete",
  INCHIRIERI: "Închirieri",
  NOUTATI: "Noutăți",
  CONTACT: "Contact",
  CURSE: "Curse la adresă",
  INFO: "Info",
};
