import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableCities} from "../servicies/travel-service";
import { dummyCitiesData } from "../../constants/data";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const citiesSlice = createSlice({
  name: "allCitiesReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableCities.pending, (state) => {
        return { ...state, data: null, isLoading: true, error: null };
      })
      .addCase(fetchAvailableCities.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAvailableCities.rejected, (state, action) => {
        return {
          ...state,
          data: dummyCitiesData,
          isLoading: false,
          error: null,
        };
      });
  },
});

export default citiesSlice.reducer;
