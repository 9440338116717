
import { useEffect } from "react";
import LocationsCard from "../components/location-card";
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { fetchAvailableLocations } from "../redux/servicies/locations-service";
import Loader from "../components/loader";

const Locations = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { data, isLoading, error } = useAppSelector((state) => state.allLocationsReducer);
  const isRoLocations = location.pathname.trim() === '/agentii-romania'

  useEffect(() => {
    dispatch(fetchAvailableLocations(isRoLocations));
  }, [dispatch]);

  return (
    <div className="locations-container">
      <div className="locations-body">
        {isLoading && <div style={{ justifyContent: "center", display: 'flex' }}><Loader /></div>}
        {error && <div style={{ color: "red", justifyContent: "center", display: 'flex' }}>Datele nu au putut fi încărcate. Vă rugăm să încercați din nou mai târziu</div>}
        {!isLoading && !error && (<>
          {isRoLocations ? (
            <div>
              <h2>Agenții Euro Fratello România</h2>
              <p>
                Oferim servicii premium de transport internațional pentru persoane și colete, cu acces ușor la informațiile necesare. Avem 9 agenții în țară pentru a vă asigura informații actualizate despre plecări, tarife, durata călătoriei și destinații. Contactați echipa noastră la datele de mai jos.
              </p>
              <p className="orange-text">
                Lista celor 9 Agenții Euro Fratello din România
              </p>
            </div>
          ) : (
            <div>
              <h2>Agenții Euro Fratello Internaționale</h2>
              <p>
                Cu 3 agenții internaționale, suntem aici pentru a satisface toate nevoile tale de călătorie în și din România. Poți obține informații, tarife pentru colete, face rezervări și primi sfaturi privind transportul internațional pentru persoane și colete. Operatorii noștri sunt mereu la dispoziția ta pentru a-ți oferi sprijinul necesar. Euro Fratello este partenerul de încredere pentru orice călătorie, fie că trimiti colete, pleci în concediu sau în interes de serviciu.
              </p>
              <p className="orange-text">
                Lista Celor 3 Agenții Euro Fratello Internaționale
              </p>
            </div>
          )}

          <div className="locations-grid">
            {
              data?.map((locationItem) =>
                <div>
                  <LocationsCard
                    location={locationItem}
                  />
                </div>
              )
            }
          </div>
        </>)}
      </div>

    </div>
  );
};

export default Locations;
