import React, { useState } from 'react';


const Calendar = ({ selectedDays, excludedDays }) => {
  const dayAbbreviations = ['Du', 'Lu', 'Ma', 'Mi', 'Joi', 'Vi', 'Sâ'];
  const months = [
    'Ian',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Iun',
    'Iul',
    'Aug',
    'Sep',
    'Oct',
    'Noi',
    'Dec',
  ];

  const [selectedDate, setSelectedDate] = useState(new Date());

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  const isExcluded = (date) => {
    const currentDate = formatDate(date);
    return excludedDays.includes(currentDate);
  };

  const renderCalendar = () => {
    const firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    const daysInMonth = getDaysInMonth(selectedDate);
    const startingDay = firstDay.getDay();
    const calendarArray = [];

    for (let i = 0; i < startingDay; i++) {
      calendarArray.push(<div className="calendar-day empty" key={`empty-${i}`}></div>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), i);
      const isExcludedDay = isExcluded(currentDate);
      if (!isExcludedDay) {
        const isSelectedDay = selectedDays.includes(currentDate.getDay());

        calendarArray.push(
          <div
            className={`calendar-day${isSelectedDay ? ' selected' : ''}`}
            key={i}
            onClick={() => handleDateClick(i)}
          >
            {i}
          </div>
        );
      } else {
        calendarArray.push(
          <div
            className={`calendar-day excluded`}
            key={i}
          >
            {i}
          </div>
        );
      }
    }

    return calendarArray;
  };

  const handleDateClick = (day) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(day);
    setSelectedDate(newDate);
  };

  return (
    <div className="calendar">
      <div className="calendar-header">
        <div className="month-name">
          {months[selectedDate.getMonth()]} {selectedDate.getFullYear()}
        </div>
        <div className="arrows">
          <div className="prev-arrow" onClick={() => setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1))}>
            &lt;
          </div>
          <div className="next-arrow" onClick={() => setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1))}>
            &gt;
          </div>
        </div>
      </div>
      <div className="calendar-days">
        {dayAbbreviations.map((day, index) => (
          <div className="calendar-day-label" key={index}>
            {day}
          </div>
        ))}
        {renderCalendar()}
      </div>
    </div>
  );
};

export default Calendar;