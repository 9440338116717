import React, { useState } from "react";
import { CheckBox } from "./checkbox";

const InvoiceInputs = ({setInvoice}) => {

    const [isChecked, setCheckBox] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [cnp, setCNP] = useState("");
    const [phone, setPhone] = useState("");

    const handleCheckboxChange = () => {
        setCheckBox(!isChecked);
        setInvoice(prevState => ({
            ...prevState,
            ["isChecked"]: !isChecked
          }));
        // setError("");
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setInvoice(prevState => ({
            ...prevState,
            ["email"]: e.target.value
          }));
        // setError(""); // Clear any previous error when the input changes
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
        setInvoice(prevState => ({
            ...prevState,
            ["name"]: e.target.value
          }));
        //setError(""); // Clear any previous error when the input changes
    };

    const handleSurnameChange = (e) => {
        setSurname(e.target.value);
        setInvoice(prevState => ({
            ...prevState,
            ["surname"]: e.target.value
          }));
        //setError(""); // Clear any previous error when the input changes
    };
    const handleCnpChange = (e) => {
        setCNP(e.target.value);
        setInvoice(prevState => ({
            ...prevState,
            ["cnp"]: e.target.value
          }));
        //setError(""); // Clear any previous error when the input changes
    };
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
        setInvoice(prevState => ({
            ...prevState,
            ["phone"]: e.target.value
          }));
        // setError(""); // Clear any previous error when the input changes
    };
    return (
        <div>
            <div className="invoice-card">
                <h2>Date facturare</h2>
                <div className="invoice-inputs-body">
                    <input
                        placeholder="Nume*"
                        value={name}
                        onChange={handleNameChange}
                    />
                    <input
                        placeholder="Prenume*"
                        value={surname}
                        onChange={handleSurnameChange}
                    />
                    <input
                        placeholder="CNP / CUI*"
                        value={cnp}
                        onChange={handleCnpChange}
                    />
                    <input
                        placeholder="Adresa ta de email*"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <input
                        placeholder="Număr de telefon*"
                        value={phone}
                        onChange={handlePhoneChange}
                    />
                     <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ marginRight: "10px" }}>
                                <CheckBox
                                    isChecked={isChecked}
                                    onCheckboxChange={handleCheckboxChange}
                                    checkboxID = {"invoiceCheckbox"}
                                />
                            </div>
                            <small>
                                Prin completarea formularului sunt de acord cu
                                <a className="btn-link" href="/informatii?pagina=Terms">
                                    {" "}
                                    Termenii și Condițiile Euro Fratello{" "}
                                </a>
                                inclusiv
                                <a className="btn-link" href="/informatii?pagina=GDPR"> Politica GDPR </a>
                                de prelucrare a datelor cu caracter personal.
                            </small>
                        </div>

                </div>

            </div>
        </div>
    );
};

export default InvoiceInputs;