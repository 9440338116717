import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const fetchAvailableLocations = createAsyncThunk(
  "fetchAvailableLocations",
  async (isRoLocations) => {
    const response = await api.get("locations?international=" + !isRoLocations);
    return response.data;
  }
);
