import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Counter from "./price-counter";

const DestinationCard = ({ countryId, countryName, img, price, returnPrice }) => {
  const [loaded, setLoaded] = useState(false); // State pentru a urmări dacă imaginea este încărcată
  let navigate = useNavigate();

  useEffect(() => {
    const image = new Image();
    image.src = img;
    image.onload = () => setLoaded(true); // Setează loaded pe true când imaginea este încărcată
  }, [img]);

  const redirectToDestination = () => {
    navigate(`/destinatie?name=${countryName}`, { state: { id: countryId } });
  }

  return (
    <div className={`card ${loaded ? "loaded" : ""}`} style={{ backgroundImage: `url(${img})` }} onClick={redirectToDestination}>
      <h5 className="card-title">{countryName}</h5>
      <div className="card-img-overlay d-flex flex-column justify-content-end">
        <div className="card-content p-2 p-md-3">
          <p className="card-text">
            Preț tur începând cu{"  "}
            <Counter endValue={price} />
            /pers
          </p>
          <p className="card-text">
            Preț retur incepand cu{"  "}
            <Counter endValue={returnPrice} />
            /pers
          </p>
        </div>
      </div>
    </div>
  )
};

export default DestinationCard;
