import React, { useState } from "react";

const PersonInput = ({ index, isAdult, setPassengers}) => {
    const [name, setName] = useState("");
    const handleNameChange = (e) => {
        setName(e.target.value);
        setPassengers(prevPassengers => {
            const newPassengers = [...prevPassengers]; 
            newPassengers[index-1] = e.target.value; 
            return newPassengers;
          });
    };
    if (isAdult) {
        return (
            <div>
                <div className="person-input-card">
                    <span>{index}. Călător adult*</span>
                    <input
                        placeholder="Nume*"
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="person-input-card">
                    <span>{index}. Călător copil*</span>
                    <input
                        placeholder="Nume*"
                        value={name}
                        onChange={handleNameChange}
                    />
                </div>
            </div>
        );
    }
};

export default PersonInput;