import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import "./style/index.scss";
import { useState } from "react";
import WelcomePage from "./pages/welcome";
import Rent from "./pages/rentals";
import Parcels from "./pages/parcels";
import Contact from "./pages/contact";
import News from "./pages/news";
import Locations from "./pages/locations";
import CheckTiket from "./pages/check_tiket";
import NewsDetails from "./pages/news-details";
import Header from "./components/header";
import Destination from "./pages/destinations";
import BuyTicket from "./pages/buy-ticket";
import InfoPage from "./pages/info-page";
import BuyTicketPage from "./pages/buy-ticket-page";
import BuyTicketFinish from "./pages/buy-ticket-finish";
import MentencePage from "./pages/mentenance";

const App = () => {
  const [isScroll, setIsScroll] = useState(false);

  const handleScroll = () => {
    setIsScroll(!isScroll);
  };
  return (
    <div className="App">
      <Router>
        <Navbar setIsScroll={handleScroll} />
        <Header />
        <Routes>
          <Route path="/mentenanta" element={<MentencePage />} />
          <Route path="/" element={<WelcomePage />} />
          <Route path="/agentii-romania" element={<Locations />} />
          <Route path="/agentii-internationale" element={<Locations />} />
          <Route path="/detalii-noutati" element={<NewsDetails />} />
          <Route path="/inchirieri" element={<Rent />} />
          <Route path="/colete" element={<Parcels />} />
          <Route path="/noutati" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/verifica-bilet" element={<CheckTiket />} />
          <Route path="/informatii" element={<InfoPage />} />
          <Route path="/destinatie" element = {<Destination />}/>
          <Route path="/detalii-bilet" element = {<BuyTicket />}/>
          <Route path="/plateste" element = {<BuyTicketPage />}/>
          <Route path="/finalizare-plata" element = {<BuyTicketFinish />}/>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer className="footer" large redirect={isScroll} />
      </Router>
    </div>
  );
};

export default App;
