import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import { List } from 'immutable';
import iosImg from "../assets/app-promote-ios.png";
import androidImg from "../assets/app-promote-android.png";
import downloadAndroidImg from "../assets/download-android.png";
import downloadIosImg from "../assets/download-ios.png";
import lowCost from "../assets/low-cost.svg";
import shild from "../assets/shield.svg";
import wating from "../assets/waiting-room.svg";
import schedule from "../assets/schedule.svg";
import DestinationCard from "../components/destination-card";
import NewsCard from "../components/news-card";
import TravelComponent from "../components/travel";
import TravelReason from "../components/travel-reason";
import React, { useEffect, useState } from "react";
import SocialItems from "../components/social";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { fetchAvailablDestinations, fetchAvailableCities } from "../redux/servicies/travel-service";
import { fetchAvailableNews } from "../redux/servicies/news-service";
import ModalComponent from "../components/bus_rides_modal";
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
  const destinationsData = useAppSelector((state) => state.allDestinationsReducer);
  const newsList = useAppSelector((state) => state.allNewsReducer);
  const citiesListData = useAppSelector((state) => state.allCitiesReducer);
  const [isModalOpen, setModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    animationTrigger();
    dispatch(fetchAvailablDestinations());
    dispatch(fetchAvailableNews());
    dispatch(fetchAvailableCities());
  }, [dispatch]);

  const animationTrigger = () => {
    const elements = document.querySelectorAll(".slideanim");
    const animationTriggered = new Array(elements.length).fill(false);

    const handleScroll = () => {
      elements.forEach((element, index) => {
        const pos = element.getBoundingClientRect().top;
        const winTop = window.scrollY;

        if (pos < winTop + 600 && !animationTriggered[index]) {
          element.classList.add("slide");
          animationTriggered[index] = true;
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }

  const handleCall = () => {
    setModalOpen(true);
  }

  const handleContact = () => {
    navigate("/contact")
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="welcome-top-container">
      <div className="welcome-top-container">
        <div className="welcome-top-section">
          <div className="welcome-top-section-text slide-text">
            <h2>Alege destinația ta preferată</h2>
            <p>cumpără-ți biletul de autocar acum!</p>
          </div>
          <div className="contact-buttons slide">
            <button
              onClick={handleCall}
              className="send-button call-button">
              Sună
            </button>

            <button
              onClick={handleContact}
              className="send-button">
              Contact
            </button>
            <ModalComponent show={isModalOpen} handleClose={handleCloseModal} />
          </div>
        </div>
        {citiesListData.data && (
          <div className="travel-item-search">
            <TravelComponent
              citiesList={citiesListData.data?.filter((location) => location.city != 'Fast Despatch Logistics Anglia' && location.city != 'Fast Dispatch Logistics Romania')}
            />
          </div>
        )}
      </div>
      {destinationsData.data && (
        <div className="destination-card-body slide">
          <div className="destination-options-container">
            <h2>Cu noi poți ajunge în</h2>
            <p>
              {destinationsData.data
                .map(item => item.country)
                .sort((a, b) => a.localeCompare(b))
                .join(', ')}
            </p>
          </div>
          <div className="destinations-card-grid">
            {destinationsData.data?.slice()
              .sort((a, b) => a.country.localeCompare(b.country))
              .filter((location) => location.country !== 'Romania')
              .map((country) => (
                <DestinationCard
                  key={country.id}
                  countryId={country.id}
                  countryName={country.country}
                  price={country.minPrice}
                  img={country.image}
                  returnPrice={country.minPriceReturn}
                />
              ))}
          </div>
        </div>
      )}
      <div className="app-promote-container slideanim">
        <div className="app-promote-body">
          <div className="app-promo-images">
            <img className="promo-image" src={iosImg} alt="euro fratello app" />
            <img className="promo-image" src={androidImg} alt="euro fratello app" />
          </div>
          <div className="app-promo-right">
            <p className="text-title">Descarcă aplicația EuroFratello</p>
            <p className="text-description">Calătorește mai simplu ca niciodată! Descarcă aplicația EuroFratello și achiziționează un bilet către destinația dorită. Simplu și rapid!</p>

            <div className="app-promo-download">
              <a href="https://play.google.com/store/apps/details?id=com.hypercode.eurofrattello" target="_blank" rel="noopener noreferrer">
                <img className="promo-image-download" src={downloadAndroidImg} alt="euro fratello download app" />
              </a>
              <a href="https://apps.apple.com/ro/app/eurofratello/id6477572520" target="_blank" rel="noopener noreferrer">
                <img className="promo-image-download" src={downloadIosImg} alt="euro fratello download app" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="travel-with-us-container slideanim">
        <h2>De ce să călătorești cu noi?</h2>
        <p>
          Descoperă motivele pentru care suntem alegerea perfectă pentru
          călătoriile tale cu autocarul
        </p>
        <div className="travel-with-us-grid">
          <TravelReason
            icon={wating}
            title="Confort"
            description="O calitate prin care ne menținem mereu mulțumiți clienții, depășindu-le de fiecare dată așteptările."
          />
          <div className="divider" />
          <TravelReason
            icon={shild}
            title="Siguranță"
            description="O calitate prin care ne menținem mereu mulțumiți clienții, depășindu-le de fiecare dată așteptările."
          />
          <div className="divider" />
          <TravelReason
            icon={schedule}
            title="Curse săptămânale"
            description="O calitate prin care ne menținem mereu mulțumiți clienții, depășindu-le de fiecare dată așteptările."
          />
          <div className="divider" />
          <TravelReason
            icon={lowCost}
            title="Prețuri avantajoase"
            description="O calitate prin care ne menținem mereu mulțumiți clienții, depășindu-le de fiecare dată așteptările."
          />
        </div>
      </div>
      {newsList.data && (
        <div className="news-container">
          <div className="news-container-body">
            <div className="news-top-section-container">
              <div className="news-top-section">
                <div className="news-left">
                  <h2 className="news-section-title">Ultimele noutăți</h2>
                  <p>
                    Cele mai noi informații și noutăți legate de călătoriile cu
                    autocar, la un clic distanță
                  </p>
                </div>
                <a href="/noutati" className="news-see-all">
                  VEZI TOATE →
                </a>
              </div>
            </div>
            <div className="news-card-grid">
              {List(newsList.data)
                .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate))
                .map((n) => (
                  <NewsCard
                    key={n.id}
                    image={n.image}
                    title={n.title}
                    description={n.content}
                    timestamp={n.publishDate}
                    readMoreLink={`/detalii-noutati?id=${n.id}`}
                  />
                ))}
            </div>
          </div>
          <div className="social-media-section">
            <h2 className="news-section-title">Mai multe noutăți pe</h2>
            <SocialItems />
          </div>
        </div>
      )}
    </div>
  );
};

export default WelcomePage;
