import React from "react";
import step1 from "../assets/parcelsStep1.svg";
import step2 from "../assets/parcelsStep2.svg";
import step3 from "../assets/parcelsStep3.svg";

const ParcelsStepCard = () => {
    return (
        <div className="step-body">
            <div className="step-card">
                <img src={step1} alt="Icon" className="step-icon" />
                <br></br>
                <span className="step-name">Pasul 1</span>
                <span className="step-description">Pregătești coletul.</span>
            </div>
            <div className="step-orange-card">
                <img src={step2} alt="Icon" className="step-icon" />
                <br></br>
                <span className="step-name-orange">Pasul 2</span>
                <span className="step-description-orange">Îl predai curierului la cel mai apropiat sediu EuroFratello.</span>
            </div>
            <div className="step-card">
                <img src={step3} alt="Icon" className="step-icon" />
                <br></br>
                <span className="step-name">Pasul 3</span>
                <span className="step-description">Și de aici, noi îl expediem către persoanele dragi ție în străinătate.</span>
            </div>
        </div>
    );
};

export default ParcelsStepCard;