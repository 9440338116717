
import React, { useState } from 'react';
import { Nav } from "../constants/navigation";
import logo from "../assets/logo-euro-fratello.svg";
import { useLocation } from 'react-router-dom';
import ModalComponent from './bus_rides_modal';

const Navbar = () => {
    const location = useLocation();
    const [isModalOpen, setModalOpen] = useState(false);

    const isNavLinkActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-0 pe-5">
            <button type="button" className="navbar-toggler me-0 ms-2" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <a href="/" className="navbar-brand ps-5">
                <img className='logo desktop-image' height="35px" src={logo} alt="Euro-Fratello Logo" />
            </a>
     
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <a href="/" className={`nav-item nav-link ${isNavLinkActive('/')}`}>{Nav.ACASA}</a>
                    <div className="nav-item dropdown">
                        <a href="/agentii" className={`nav-link dropdown-toggle ${isNavLinkActive('/agentii-romania') || isNavLinkActive('/agentii-internationale')}`} data-bs-toggle="dropdown">{Nav.AGENTII}</a>
                        <div className="dropdown-menu bg-light m-0">
                            <a href="/agentii-romania" className={`dropdown-item ${isNavLinkActive('/agentii-romania')}`}>{Nav.AGENTII_ROMANIA}</a>
                            <a href="/agentii-internationale" className={`dropdown-item ${isNavLinkActive('/agentii-internationale')}`}>{Nav.AGENTII_INTERNATIONALE}</a>
                        </div>
                    </div>
                    <a href="/inchirieri" className={`nav-item nav-link ${isNavLinkActive('/inchirieri')}`}>{Nav.INCHIRIERI}</a>
                    <a href="/colete" className={`nav-item nav-link ${isNavLinkActive('/colete')}`}>{Nav.COLETE}</a>
                    <a href="/noutati" className={`nav-item nav-link ${isNavLinkActive('/noutati') || isNavLinkActive('/detalii-noutati')}`}>{Nav.NOUTATI}</a>

                    <a href="/contact" className={`nav-link nav-link ${isNavLinkActive('/contact') || isNavLinkActive('/verifica-bilet')}`}>{Nav.CONTACT}</a>
                </div>
                <a className="btn btn-primary px-3 d-none d-lg-block" onClick={handleOpenModal}>  {Nav.CURSE}</a>
            </div>
            <ModalComponent show={isModalOpen} handleClose={handleCloseModal} />
        </nav>
    );
};

export default Navbar;
