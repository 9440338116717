import React, { useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import LogoSvg from "../assets/logo-euro-fratello-white.svg";
import logoHypercode from "../assets/hypercode-logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { CheckBox } from "./checkbox";
import { RegexValidation } from "../constants/general";
import SocialItems from "./social";
import { subscribeMail } from "../redux/servicies/admin-euro";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import Phone from "./phone";

const Footer = () => {
  const [isChecked, setCheckBox] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();

  const handleCheckboxChange = () => {
    setCheckBox(!isChecked);
    setError("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear any previous error when the input changes
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Regular expression for email validation
    const emailRegex = RegexValidation.EMAIL_REGEX;
    if (!email) {
      setError("Adresa de email este obligatorie.");
    } else if (!email.match(emailRegex)) {
      setError("Adresa de email nu este validă.");
    } else if (!isChecked) {
      setError(
        "Trebuie să fiți de acord cu politica GDPR pentru a putea să vă abonați la newsletter"
      );
    } else {

      // Send Email to server
      const info = {
        email: email,
        accept: isChecked
      }
      dispatch(subscribeMail(info))
      console.log("Email:", email);
      setEmail("");
      setCheckBox("");
    }
  };

  return (
    <div className="c-footer">
      <div className="c-footer-container row">
        <div className="c-left-section col-lg-3 col-md-6">
          <img src={LogoSvg} alt="Euro-Fratello Logo" className="logo" />
          <p>
            Euro-Fratello este o marcă înregistrată ce aparține de SC Transport
            Auto Severin S.A.
          </p>
          <SocialItems />
        </div>
        <div className="c-midle-left-section col-lg-3 col-md-6">
          <h3>Linkuri rapide</h3>
          <ul className="quick-links">
            <li>
              <a className="btn-link" href="/informatii?pagina=Terms">
                <i className="fa fa-chevron-right"></i> Termeni și condiții
                transport
              </a>
            </li>
            <li>
              <a className="btn-link" href="/ANCOM.pdf">
                <i className="fa fa-chevron-right"></i> ANCOM
              </a>
            </li>
            <li>
              <a className="btn-link" href="/informatii?pagina=GDPR">
                <i className="fa fa-chevron-right"></i> Politica GDPR
              </a>
            </li>
            <li>
              <a className="btn-link" href="/informatii?pagina=Cookies">
                <i className="fa fa-chevron-right"></i> Politica Cookies
              </a>
            </li>
          </ul>
        </div>
        <div className="c-midle-right-section col-lg-3 col-md-6">
          <h3>Date de contact</h3>
          <ul className="contact-links">
            <li>
              <i className="fa fa-map-marker"></i> Vatra dornei, Calea
              Transilvaniei, nr. 135
            </li>
            <hr></hr>
            <div className="contact-numbers">
              <Phone phoneNumber={"0741258455"} />
              <br></br>
              <Phone phoneNumber={"0724122272"} />
              <br></br>
              <Phone phoneNumber={"0741258456"} />
              <br></br>
              <Phone phoneNumber={"0756086166"} />
            </div>
            <hr></hr>
            <li>
              <i className="fa fa-envelope"></i> contact@euro-fratello.ro
            </li>
          </ul>
        </div>
        <div className="c-right-section col-lg-3 col-md-6">
          <h3>Aboneaza-te la newsletter</h3>
          <p>
            Introdu adresa de e-mail pentru a primi oferte speciale, informații
            despre evenimente și multe alte noutăți.
          </p>
          <form onSubmit={handleSubmit}>
            {error && (
              <div className="error-message red-text">
                <br></br>
                {error}
              </div>
            )}
            <div className="newsletter-input-group">
              <input
                placeholder="Adresa ta de email"
                value={email}
                onChange={handleEmailChange}
              />
              <button type="submit">
                <i className="fa fa-envelope"></i>
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "-15px",
              }}
            >
              <div style={{ marginRight: "10px" }}>
                <CheckBox
                  isChecked={isChecked}
                  onCheckboxChange={handleCheckboxChange}
                  checkboxID={"footerCheckbox"}
                />
              </div>
              <small>
                Prin completarea formularului sunt de acord cu
                <a href="/informatii?pagina=Terms">
                  {" "}
                  Termenii si Conditiile Euro Fratello{" "}
                </a>
                inclusiv
                <a href="/informatii?pagina=GDPR"> Politica GDPR </a>
                de prelucrare a datelor cu caracter personal.
              </small>
            </div>
          </form>
        </div>
        <div className="bottom-section">
          <hr />
          <div className="bottom-section-content">
            <p> Created by <a href="https://www.hypercode.ro/"><img src={logoHypercode} alt="Hypercode Logo" height="30px" style={{ marginLeft: '10px' }} /></a></p>
            <p>Copyright © 2024. Toate drepturile rezervate.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
