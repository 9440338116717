import CountryCard from "../components/country-card"
import ParcelsStepCard from "../components/parcels-step-card"
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { fetchAvailableParcels } from "../redux/servicies/parcels-service";
import Loader from "../components/loader";

const Parcels = () => {

  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useAppSelector((state) => state.allParcelsReducer);

  useEffect(() => {
    dispatch(fetchAvailableParcels());
  }, [dispatch]);
  return (
    <div className="parcels-container">
      <div className="countries-card-body">
        {isLoading && <div style={{ justifyContent: "center", display: 'flex' }}><Loader /></div>}
        {error && <div style={{ color: "red", justifyContent: "center", display: 'flex' }}>Datele nu au putut fi încărcate. Vă rugăm să încercați din nou mai târziu</div>}
        {!isLoading && !error && (<>
          <div className="title-container">
            <h2>Transport International de colete</h2>
            <p>
              Trimiteți colete în străinătate la prețuri începând de la
              <span className="text-orange">
                {" "}
                1.5 Euro/kilogram
              </span>
              <span>
                {" "}
                în țări precum
              </span>
              <span className="text-orange">
                {" "}
                Austria, Germania, Belgia, Anglia, Danemarca, Suedia și Norvegia.
              </span>
            </p>
          </div>
          <div class="countries-grid-container">
            <div class="row g-3 justify-content-center ">
              {data?.map((countryItem) =>
                <div class="col-md-6 col-lg-3 wow " data-wow-delay="0.1s">
                  <CountryCard
                    country={countryItem}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="title-container-2">
            <h2>Trimite acum în străinătate din oricare colț al țării.</h2>
            <p>
              Cum? Foarte simplu, chiar din confortul casei tale.
            </p>
          </div>
          <div className="steps-body">
            <ParcelsStepCard />
          </div>
          <hr className="line"></hr>
          <div className="title-container-3">
            <span className="title-obs">
              Observații
            </span>
            <ul>
              <li>Plicurile cu acte și coletele până în 10 kg se vor încasa cu minim 15 €/£;</li>
              <li>Colecțiile voluminoase (canapele, mașini de spălat etc.) – prețul se va face la fața locului;</li>
              <li>Societatea nu își asumă responsabilitatea pentru banii sau lucrurile de valoare nedeclarate care se află în colete;</li>
              <li>Interzis transportul de țigări, băuturi spirtoase, antibiotice, medicamente cu regim special și alte produse interzise prin lege;</li>
              <li>Transportul de păsări și animale vii este interzis;</li>
            </ul>
          </div>
        </>
        )}

      </div>
    </div>
  );
};

export default Parcels;
