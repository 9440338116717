import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailablDestinations} from "../servicies/travel-service";
import { dummyDestinationsData } from "../../constants/data";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const destinationsSlice = createSlice({
  name: "allDestinationsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailablDestinations.pending, (state) => {
        return { ...state, data: null, isLoading: true, error: null };
      })
      .addCase(fetchAvailablDestinations.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAvailablDestinations.rejected, (state, action) => {
        return {
          ...state,
          data: dummyDestinationsData,
          isLoading: false,
          error: null,
        };
      });
  },
});

export default destinationsSlice.reducer;
