import React, { useState } from "react";
import { CheckBox } from "./checkbox";
import Phone from "./phone";

const ExtraInfo = ({ setExtraInfo, ticket }) => {
    const [message, setMessage] = useState("");
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        setExtraInfo(prevState => ({
            ...prevState,
            ["details"]: e.target.value
        }));
    };

    const shouldDisplayExtraInfoReturn = (
        ticket.returnTrip &&
        (ticket.returnTrip.to.includes('Austria') || ticket.returnTrip.departure.includes('Austria') ||
        ticket.returnTrip.to.includes('Belgia') || ticket.returnTrip.departure.includes('Belgia'))
      );
    
      const shouldDisplayExtraInfoOneWay = (
        ticket.oneWay &&
        (ticket.oneWay.to.includes('Austria') || ticket.oneWay.to.includes('Austria') ||
        ticket.oneWay.to.includes('Belgia') || ticket.oneWay.departure.includes('Belgia'))
      );
    

      const shouldDisplayExtraInfo = shouldDisplayExtraInfoReturn || shouldDisplayExtraInfoOneWay;
    

    return (
        <div>
            <div className="extra-info-card">
                <h2>Detalii/Observații</h2>
                <div className="extra-info-body">
                    {shouldDisplayExtraInfo && <>
                        <span>Pentru preluare de la adresă contactați-ne telefonic:</span>
                        <Phone
                            phoneNumber={"+40 741 258 455"}
                        />
                    </>}
                    <textarea className="message-text"
                        placeholder="Detalii"
                        value={message}
                        onChange={handleMessageChange}
                    > </textarea>

                </div>

            </div>
        </div>
    );
};

export default ExtraInfo;