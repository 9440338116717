import React, { useState } from "react";
import { CheckBox } from "./checkbox";
import { RegexValidation } from "../constants/general";
import Dropdown from './dropdown';
import { sendContactMessage } from "../redux/servicies/admin-euro";
import { useAppDispatch, useAppSelector } from "../redux/store/store";

const ContactUsForm = () => {
    const [isChecked, setCheckBox] = useState(false);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [selectedFrom, setSelectedFrom] = useState('');
    const options = ['Transport colete', 'Transport persoane', 'Închirieri', 'Informații generale'];
    
    const dispatch = useAppDispatch();

    const handleCheckboxChange = () => {
        setCheckBox(!isChecked);
        setError("");
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError(""); // Clear any previous error when the input changes
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
        setError(""); // Clear any previous error when the input changes
    };
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
        setError(""); // Clear any previous error when the input changes
    };
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        setError(""); // Clear any previous error when the input changes
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Regular expression for email validation
        const emailRegex = RegexValidation.EMAIL_REGEX;
        const phoneRagex = RegexValidation.PHONE_REGEX;
        if (!name) {
            setError("Numele este obligatoriu.");
        } else if (!phone) {
            setError("Numărul de telefon este obligatoriu.");
        } else if (!phone.match(phoneRagex)) {
            setError("Numărul de telefon nu este valid.");
        } else if (!email) {
            setError("Adresa de email este obligatorie.");
        } else if (!email.match(emailRegex)) {
            setError("Adresa de email nu este validă.");
        } else if (!message) {
            setError("E obligatoriu să introduceți un mesaj.");
        } else if (!isChecked) {
            setError(
                "Trebuie să fiți de acord cu politica GDPR pentru a putea trimite un mesaj");
        } else {
            // Send Email to server
            const formData = {
                nume: name,
                telefon: phone,
                email: email,
                subiect: selectedFrom,
                mesaj: message,
                accept: isChecked
            }
            dispatch(sendContactMessage(formData))
            setEmail("");
            setName("");
            setPhone("");
            setMessage("");
            setCheckBox("");
            setSelectedFrom("");
        }
    };

    const handleFromSelect = (value) => {
        setSelectedFrom(value);
    };

    return (
        <div>
            <div className="contact-us-form-body">
                <h2>
                    Vrei să iei legătura cu noi?
                </h2>
                <div className="form-body">
                    <form onSubmit={handleSubmit}>
                        {error && (
                            <div className="error-message red-text">
                                <br></br>
                                {error}
                            </div>
                        )}
                        <input
                            placeholder="Nume*"
                            value={name}
                            onChange={handleNameChange}
                        />
                        <input
                            placeholder="Număr de telefon*"
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                        <input
                            placeholder="Adresa ta de email*"
                            value={email}
                            onChange={handleEmailChange}
                        />

                        <div className="subject-dropdown">
                            <Dropdown
                                options={options}
                                selectedOption={selectedFrom}
                                onSelect={handleFromSelect}
                                placeholder={"Subiect"}
                            />
                        </div>

                        <textarea className="message-text"
                            placeholder="Mesaj*"
                            value={message}
                            onChange={handleMessageChange}
                        > </textarea>

                        <div
                            style={{
                                display: "flex",
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ marginRight: "10px" }}>
                                <CheckBox
                                    isChecked={isChecked}
                                    onCheckboxChange={handleCheckboxChange}
                                    checkboxID = {"contactUsCheckbox"}
                                />
                            </div>
                            <small>
                                Prin completarea formularului sunt de acord cu
                                <a className="btn-link" href="/informatii?pagina=Terms">
                                    {" "}
                                    Termenii și Condițiile Euro Fratello{" "}
                                </a>
                                inclusiv
                                <a className="btn-link" href="/informatii?pagina=GDPR"> Politica GDPR </a>
                                de prelucrare a datelor cu caracter personal.
                            </small>
                        </div>
                        <div className="button-container">
                            <button
                                onClick={handleSubmit}
                                className="send-button">
                                Trimite →
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactUsForm;