import { createAsyncThunk } from "@reduxjs/toolkit";
import api from './api-euro';

  export const sendContactMessage = createAsyncThunk(
    "sendContactMessage",
    async (contactInfo) => {
      const url = `ajax/Contact`;
      const response = await api.post(url, contactInfo);
      return response.data;
    }
  ); 


  export const subscribeMail = createAsyncThunk(
    "subscribeMail",
    async (info) => {
      const response = await api.post("ajax/Contact", info)
      return response.data;
    }
  );