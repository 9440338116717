import { combineReducers } from "@reduxjs/toolkit";
import locationsReducer from "./locations-reducer";
import newsReducer from "./news-reducer";
import busesReducer from "./buses-reducer";
import parcelsReducer from "./parcels-reducer";
import destinationReducer from "./destinations-reducer";
import citiesReducer from "./cities-reducer";
import tripDatesReducer from "./trip-dates-reducer";
import destinationDetails from "./destination-details-reducer";
import priceReducer from "./price-reducer";

export const appReducers = combineReducers({
  allLocationsReducer: locationsReducer,
  allNewsReducer: newsReducer,
  allBusesReducer: busesReducer,
  allParcelsReducer: parcelsReducer,
  allDestinationsReducer: destinationReducer,
  allCitiesReducer: citiesReducer,
  allTripDatesReducer: tripDatesReducer,
  allDestinationDetailsReducer: destinationDetails,
  priceReducer: priceReducer
});
