import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPageDetails } from "../redux/servicies/page-service";
import Loader from "../components/loader";

const InfoPage = () => {
  const dispatch = useDispatch();
  const [pageHTML, setPageHTML] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const actionResult = await dispatch(
          fetchPageDetails(queryParams.get("pagina"))
        );
        const data = actionResult.payload;
        setPageHTML(data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  if (loading)
    return (
      <div className="info-page-container">
        {" "}
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Loader />
        </div>
      </div>
    );
  if (error)
    return (
      <div className="info-page-container">
        {" "}
        {error && (
          <div
            style={{ color: "red", justifyContent: "center", display: "flex" }}
          >
            Datele nu au putut fi încărcate. Vă rugăm să încercați din nou mai
            târziu
          </div>
        )}
      </div>
    );

  return (
    <div className="info-page-container">
      <div
        className="html-content"
        dangerouslySetInnerHTML={{ __html: pageHTML }}
      />
    </div>
  );
};

export default InfoPage;
