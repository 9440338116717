import React from "react";
import PersonInput from "./person-input";

const PassengersInput = ({ noAdults, noChildren, setPassengers }) => {
    const renderAdultInputs = () => {
        const adultInputs = [];
        for (let i = 0; i < noAdults; i++) {
            adultInputs.push(<PersonInput index={i + 1} isAdult={true} setPassengers={setPassengers}/>);
        }
        return adultInputs;
    };

    const renderKidsInputs = () => {
        const kidsInputs = [];
        for (let i = 0; i < noChildren; i++) {
            kidsInputs.push(<PersonInput index={parseInt(noAdults, 10) + i + 1} isAdult={false} setPassengers={setPassengers} />);
        }
        return kidsInputs;
    };

    return (
        <div>
            <div className="passengers-input-card">
                <h2> Pasageri </h2>
                <div className="passengers-input-body">
                    <div>
                        {renderAdultInputs()}
                    </div>
                    <div>
                        {renderKidsInputs()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PassengersInput;