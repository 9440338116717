import { createSlice } from "@reduxjs/toolkit";
import { fetchPrice } from "../servicies/price-service";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};
const priceSlice = createSlice({
    name: "priceReducer",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchPrice.pending, (state) => {
          return { ...state, data: null, isLoading: true, error: null };
        })
        .addCase(fetchPrice.fulfilled, (state, action) => {
          return {
            ...state,
            data: action.payload,
            isLoading: false,
            error: null,
          };
        })
        .addCase(fetchPrice.rejected, (state, action) => {
          console.log("rejected")
          return {
            ...state,
            data: null,
            isLoading: false,
            error: null,
          };
        });
    },
  });
  
  export default priceSlice.reducer;