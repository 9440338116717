import React from 'react';
import CheckBoxFillSvg from "../assets/ic-checkbox-fill.svg";
import CheckBoxSvg from "../assets/ic-checkbox.svg";

export const CheckBox = (props) => {
  const { isChecked, onCheckboxChange, checkboxID } = props;

  return (
    <div>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onCheckboxChange}
        style={{ display: 'none' }}
        id={checkboxID}
      />
      <label htmlFor={checkboxID} className="custom-checkbox-label">
        {isChecked ? (
          <img src={CheckBoxFillSvg} alt="Checkbox Checked" />
        ) : (
          <img src={CheckBoxSvg} alt="Checkbox Unchecked" />
        )}
      </label>
    </div>
  );
};
