import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableTripDates } from "../servicies/travel-service"

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const tripDatesSlice = createSlice({
  name: "allTripDatesReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableTripDates.pending, (state) => {
        return { ...state, data: null, isLoading: true, error: null };
      })
      .addCase(fetchAvailableTripDates.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAvailableTripDates.rejected, (state, action) => {
        return {
          ...state,
          data: null,
          isLoading: false,
          error: null,
        };
      });
  },
});

export default tripDatesSlice.reducer;
