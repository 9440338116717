import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const fetchPageDetails = createAsyncThunk(
  "fetchPage",
  async (pageType) => {
    const response = await api.get("page?pageType=" + pageType);
    return response.data;
  }
);
