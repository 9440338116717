import React, { useState, useEffect, useRef } from 'react';

function DropdownLocations({ options, selectedOption, onSelect, placeholder }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef(null);

  const containerWidth = dropdownRef.current?.offsetWidth;
  const maxOptionWidth = containerWidth ? containerWidth : 'auto';

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
    setSearchValue("")
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown"
    style={{ maxWidth: maxOptionWidth }} 
    ref={dropdownRef}>
      {isOpen ? (
        <div>
          <input
            type="text"
            placeholder="Căutare..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className="search-input"
          />
          <div className="options show">
            {options
              .filter((option) =>
                option.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((option, index) => (
                <div
                  key={index}
                  className={`option ${option === selectedOption ? "selected" : ""}`}
                  onClick={() => handleSelect(option)}
                >
                  {option}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          className={`selected-option${!selectedOption ? "-hint" : ""}`}
        
          onClick={() => setIsOpen(true)}
        >
          {selectedOption ? selectedOption : placeholder}
        </div>
      )}
    </div>
  );
}

export default DropdownLocations;
