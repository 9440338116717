import ExtraInfo from "../components/extra-info-card";
import InvoiceInputs from "../components/invoice-inputs-card";
import PassengersInput from "../components/passengers-card";
import ProgressBar from "../components/progres-bar-container";
import { RegexValidation } from "../constants/general";
import TicketInfo from "../components/ticket-info";
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";

const BuyTicket = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const ticket = location.state.ticket
    const departureId = location.state.departureId;
    const toId = location.state.toId;
    const length = ticket.adultNo + ticket.childrenNo;
    const price = ticket.totalPrice;
    const [passengers, setPassengers] = useState(Array(length).fill(""));
    const [invoice, setInvoice] = useState({
        email: "",
        name: "",
        surname: "",
        cnp: "",
        phone: "",
        isChecked: false
    });

    const [extraInfo, setExtraInfo] = useState({
        details: ""
    });
    const [error, setError] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(passengers)
        console.log(invoice)
        const emailRegex = RegexValidation.EMAIL_REGEX;
        const phoneRagex = RegexValidation.PHONE_REGEX;
        const isAllNonEmptyStrings = passengers.every(passenger => passenger !== '');
        if (!isAllNonEmptyStrings) {
            setError("Completați numele tuturor pasagerilor!")

        } else if (invoice.name == '') {
            setError("Completați numele pentru factură!")
        } else if (invoice.surname == '') {
            setError("Completați prenumele pentru factură!")
        } else if (invoice.phone == '') {
            setError("Completați numărul de telefon pentru factură!")
        } else if (!invoice.phone.match(phoneRagex)) {
            setError("Numărul de telefon nu este valid.")
        } else if (invoice.cnp == '') {
            setError("Completați CNP/CUI pentru factură!")
        } else if (!/^\d+$/.test(invoice.cnp)) {
            setError("CNP/CUI nu este valid. Introduceți doar cifre.");
        } else if (invoice.email == '') {
            setError("Completați email-ul pentru factură!")
        } else if (!invoice.email.match(emailRegex)) {
            setError("Email-ul nu este valid.")
        } else if (!invoice.isChecked) {
            setError("Trebuie să fiți de acord cu politica GDPR")
        } else {
            navigate(`/plateste`, { state: { ticket: ticket, passengers: passengers, invoice: invoice, extra: extraInfo, departureId: departureId, toId: toId } });
            setError("")
        }

    };

    function validateCNP(value) {
        var re = /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/,
            bigSum = 0,
            rest = 0,
            ctrlDigit = 0,
            control = '279146358279',
            i = 0;
        if (re.test(value)) {
            for (i = 0; i < 12; i++) {
                bigSum += value[i] * control[i];
            }
            ctrlDigit = bigSum % 11;
            if (ctrlDigit === 10) {
                ctrlDigit = 1;
            }

            if (ctrlDigit !== parseInt(value[12], 10)) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    };

    return (
        <div className="buy-ticket-container">
            <div className="progress-bar-container">
                <ProgressBar
                    isLastStep={false}
                    isFinished={false}
                />
            </div>
            <div className="buy-ticket-body">
                <div className="inputs-body">
                    <PassengersInput
                        noAdults={ticket.adultNo}
                        noChildren={ticket.childrenNo}
                        setPassengers={setPassengers}
                    />

                    <InvoiceInputs setInvoice={setInvoice} />

                    <ExtraInfo setExtraInfo={setExtraInfo} ticket={ticket} />

                    {error && (
                        <div className="error-message red-text">
                            <br></br>
                            * {error}
                        </div>
                    )}
                    <div className="button-container">
                        <button
                            onClick={handleSubmit}
                            className="send-button">
                            Continuă →
                        </button>
                    </div>
                </div>
                <div className="info-body">
                    <TicketInfo
                        ticket={ticket}
                        isTwoWay={false}
                    />
                    {ticket.type != "oneWay" ? <div><TicketInfo
                        ticket={ticket}
                        isTwoWay={true}
                    /></div> : <div> <></> </div>}
                    <div className="price-container">
                        <span className="price-text">Total</span>
                        <span className="price-text">{price} €<span className="price-text"> ({ticket.ronTotalPrice} lei)</span></span>
                    </div>

                    <div className="info-message">
                        <span className="bold-text">
                            <span className="orange-text">ATENȚIE:</span>{' '}
                            {/* {returnMessage} */}
                            Ora de plecare este estimativă.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuyTicket;
