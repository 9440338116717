import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableBus } from "../servicies/bus-service";
import { dummyBussesData } from "../../constants/data";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const allBusesSlice = createSlice({
  name: "allBusesReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableBus.pending, (state) => {
        return { ...state, data: null, isLoading: true, error: null };
      })
      .addCase(fetchAvailableBus.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAvailableBus.rejected, (state, action) => {
        return {
          ...state,
          data: dummyBussesData,
          isLoading: false,
          error: null,
        };
      });
  },
});

export default allBusesSlice.reducer;