import React from "react";

const TicketInfoReview = ({ passengers, invoice, extra }) => {
    const renderPassengers = () => {
        const passengerInputs = [];
        for (let i = 0; i < passengers.length; i++) {
            passengerInputs.push(<div className="text-container"><span className="text" >{i + 1}. {passengers[i]}</span></div>);
        }
        return passengerInputs;
    };
    return (
        <div className="ticket-info-review-body">
            <div className="passengers-info">
                <h2>Pasageri</h2>
                <div className="passengers-body">
                    {renderPassengers()}
                </div>
            </div>
            <div className="invoice-info">
                <h2>Date facturare</h2>
                <div className="invoice-body">
                    <div className="text-container">
                        <span>Nume: {invoice.name}</span>
                    </div>
                    <div className="text-container">
                        <span>Prenume: {invoice.surname}</span>
                    </div>
                    <div className="text-container">
                        <span>CNP/CUI: {invoice.cnp}</span>
                    </div>
                    <div className="text-container">
                        <span>Telefon: {invoice.phone}</span>
                    </div>
                    <div className="text-container">
                        <span>Email: {invoice.email}</span>
                    </div>
                </div>
            </div>

            <div className="extra-info">
                <h2>Detalii/Observații</h2>
                <div className="extra-body">
                    {extra.details != '' ? (
                        <div className="text-container">
                            <span>{extra.details}</span>
                        </div>
                    ) : "-"}
                </div>
            </div>

        </div>
    )
}
export default TicketInfoReview;