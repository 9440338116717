import React from "react";

const CountryCard = ({ country }) => {
    return (
        <div>
            <div className="country-card">
                <img src={country.image} alt="Icon" className="country-icon" />
                <br></br>
                <span className="country-name">{country.countryName}</span>
                <span className="country-price">{country.parcelPricePerKg}</span>
            </div>
        </div>
    );
};

export default CountryCard;