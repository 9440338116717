import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const fetchAvailableNews = createAsyncThunk(
  "fetchAvailableNews",
  async () => {
    const response = await api.get("blog");
    return response.data;
  }
);
