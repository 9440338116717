import React, { useState, useEffect, useRef } from 'react';

function Dropdown({ options, selectedOption, onSelect, placeholder, prefix }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className={`selected-option${!selectedOption ? "-hint" : ""}`}
        onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? prefix ? prefix + ": " + selectedOption : selectedOption : placeholder}
      </div>
      <div className={`options ${isOpen ? "show" : ""}`}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`option ${option === selectedOption ? "selected" : ""}`}
            onClick={() => handleSelect(option)}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;