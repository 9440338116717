import React from "react";
import busImage from "../assets/ticket_info_icon.svg";

const TicketInfo = ({ ticket, isTwoWay }) => {
    const options = {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
    };

    const inputDateString = isTwoWay ? ticket.returnTrip.date : ticket.oneWay.date;
    const inputDate = new Date(inputDateString);
    const formattedDate = inputDate.toLocaleDateString('ro-RO', options);
    const departure = isTwoWay ? ticket.returnTrip.departure : ticket.oneWay.departure;
    const destination = isTwoWay ? ticket.returnTrip.to : ticket.oneWay.to;
    const title = isTwoWay ? "Călătorie întors" : "Călătorie dus";
    const departureHour = isTwoWay ? ticket.returnTrip.departureHour : ticket.oneWay.departureHour;

    return (
        <div className="ticket-container">
            <div className="title-container">
                <h3>{title}</h3>
                <h3 className="orange-title">
                🗓️{" "} {formattedDate}{" "}🕒{" "}{ departureHour}
                </h3>
            </div>
            <div className="route-container">
                <img src={busImage} alt="Icon" className="ticket-icon" />
                <div className="destinations-container">
                    <span>{departure}</span>
                    <span>{destination}</span>
                </div>
            </div>
            <hr className="line"></hr>
            <div className="footer-container">
                <h2>{ticket.persons} <span>{"  "}adulți{"  "}</span> <span>{ticket.adultNo},</span> <span>{"  "}copii{"  "}</span><span>{ticket.childrenNo}</span></h2>
                <h2 className="orange-title">{ticket.price}</h2>
            </div>
        </div>
    );
};

export default TicketInfo;
