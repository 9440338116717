import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
import apiEuro from "./api-euro";

export const fetchAvailablDestinations = createAsyncThunk(
  "fetchAvailablDestinations",
  async () => {
    const response = await api.get("destinations");
    return response.data;
  }
);

export const fetchAvailableCities = createAsyncThunk(
  "fetchAvailableCities",
  async () => {
    const response = await api.get("destinations/locations");
    return response.data;
  }
);

export const fetchAvailableTripDates = createAsyncThunk(
  "fetchAvailableTripDates",
  async (data) => {
    const url = `destinations/trip/dates?type=${data.tripType}&departure=${data.departure}&destination=${data.destination}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchDestinationDetails = createAsyncThunk(
  "fetchDestinationDetails",
  async (countryId) => {
    const url = `destinations/cities?countryId=${countryId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const createBooking = createAsyncThunk("createBooking", async (data) => {
  const url = `booking`;
  const response = await api.post(url, data);
  return response.data;
});

export const paymentConfirmation = createAsyncThunk(
  "paymentConfirmation",
  async (orderId) => {
    const url = `/order-status/${orderId}`;
    const response = await apiEuro.get(url);
    return response.data;
  }
);

export const generateInvoice = createAsyncThunk(
  "generateInvoice",
  async (data) => {
    const url = `payment-handling/invoice`;
    const response = await api.post(url, data);
    return response.data;
  }
);
