import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import { List } from 'immutable';
import iosImg from "../assets/app-promote-ios.png";
import androidImg from "../assets/app-promote-android.png";
import downloadAndroidImg from "../assets/download-android.png";
import downloadIosImg from "../assets/download-ios.png";
import lowCost from "../assets/low-cost.svg";
import shild from "../assets/shield.svg";
import wating from "../assets/waiting-room.svg";
import schedule from "../assets/schedule.svg";
import DestinationCard from "../components/destination-card";
import NewsCard from "../components/news-card";
import TravelComponent from "../components/travel";
import TravelReason from "../components/travel-reason";
import React, { useEffect, useState } from "react";
import SocialItems from "../components/social";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { fetchAvailablDestinations, fetchAvailableCities } from "../redux/servicies/travel-service";
import { fetchAvailableNews } from "../redux/servicies/news-service";
import ModalComponent from "../components/bus_rides_modal";
import { useNavigate } from 'react-router-dom';

const MentencePage = () => {
  const destinationsData = useAppSelector((state) => state.allDestinationsReducer);
  const newsList = useAppSelector((state) => state.allNewsReducer);
  const citiesListData = useAppSelector((state) => state.allCitiesReducer);
  const [isModalOpen, setModalOpen] = useState(false);

  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    animationTrigger();
    dispatch(fetchAvailablDestinations());
    dispatch(fetchAvailableNews());
    dispatch(fetchAvailableCities());
  }, [dispatch]);

  const animationTrigger = () => {
    const elements = document.querySelectorAll(".slideanim");
    const animationTriggered = new Array(elements.length).fill(false);

    const handleScroll = () => {
      elements.forEach((element, index) => {
        const pos = element.getBoundingClientRect().top;
        const winTop = window.scrollY;

        if (pos < winTop + 600 && !animationTriggered[index]) {
          element.classList.add("slide");
          animationTriggered[index] = true;
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }

  const handleCall = () => {
    setModalOpen(true);
  }

  const handleContact = () => {
    navigate("/contact")
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="welcome-top-container">
      <div className="welcome-top-container">
        <div className="welcome-top-section">
          <div className="welcome-top-section-text slide-text">
            <h2>Ne cerem scuze pentru inconvenient, site-ul nostru este în mentenanță momentan.
            </h2>
            <p>Revenim în curând cu o experiență îmbunătățită!</p>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default MentencePage;
