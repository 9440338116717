import React, { useState, useEffect, useRef } from 'react';
import locationPinIcon from "../assets/ic-location-pin.svg";
import calendarIcon from "../assets/ic-calendar.svg";
import peopleIcon from "../assets/ic-people.svg";
import switchDestIcon from "../assets/ic_switch_dest.svg";
import switchContainerIcon from "../assets/ic_switch_container.svg";
import DropdownLocations from './dropdown-locations';
import Dropdown from './dropdown';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { fetchAvailableTripDates } from "../redux/servicies/travel-service";
import format from 'date-fns/format';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ro from 'date-fns/locale/ro';
import { fetchPrice } from '../redux/servicies/price-service';
import Loader from './loader';

registerLocale('ro', ro);
setDefaultLocale('ro');

const TravelComponent = ({ citiesList }) => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const itemRef = useRef(null);
  const datePickerRef = useRef(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const defaultLocations = citiesList.map(location => `${location.city}, ${location.country}`);
  defaultLocations.sort((a, b) => {
    return a.localeCompare(b);
  });
  const [maxOptionWidth, setMaxOptionWidth] = useState('auto');
  const [selectedType, setSelectedWay] = useState('oneWay');
  const [selectedFrom, setSelectedFrom] = useState('');
  const [selectedTo, setSelectedTo] = useState('');
  const [selectedOneWayDate, setSelectedOneWayDate] = useState("");
  const [selectedTwoWayDate, setSelectedTwoWayDate] = useState("");
  const [selectedPeople, setSelectedPeople] = useState("1");
  const [selectedChildren, setSelectedChildren] = useState(0);
  const [locationsFrom, setLocationsFrom] = useState(defaultLocations);
  const [locationsTo, setLocationsTo] = useState(defaultLocations);
  const [isRotated, setRotated] = useState(true);

  const tripDatesData = useAppSelector((state) => state.allTripDatesReducer);

  const seats = Array.from({ length: 20 }, (_, index) => (index + 1).toString());
  const seatsChild = Array.from({ length: 20 }, (_, index) => (index).toString());

  const updateMaxOptionWidth = () => {
    const spaceBetween = 5
    const containerWidth = itemRef.current?.offsetWidth;
    const newMaxOptionWidth = containerWidth ? containerWidth + spaceBetween : 'auto';
    setMaxOptionWidth(newMaxOptionWidth);
  };

  useEffect(() => {
    updateMaxOptionWidth()
    window.addEventListener('resize', updateMaxOptionWidth);
    return () => {
      window.removeEventListener('resize', updateMaxOptionWidth);
    };
  }, []);

  const featchTripsDatesData = (type, selectedFromValue, selectedToValue) => {
    if (selectedFromValue && selectedToValue && selectedFromValue !== selectedToValue) {
      const data = {
        tripType: type,
        departure: getLocationByValue(selectedFromValue).id,
        destination: getLocationByValue(selectedToValue).id
      }
      dispatch(fetchAvailableTripDates(data));
    }
  }

  const handleKeyDown = (e) => {
    e.preventDefault();
  };

  const handleRadioChange = (event) => {
    const value = event.target.value
    setSelectedWay(value);
    setSelectedTwoWayDate('')
    featchTripsDatesData(value, selectedFrom, selectedTo)
  };

  const filterLocationsByCountry = (selectedCountry) => {
    const filteredLocations = citiesList.filter(
      (location) => selectedCountry === 'Romania'
        ? location.country !== 'Romania'
        : location.country === 'Romania'
    );
    return filteredLocations.map(
      (location) => `${location.city}, ${location.country}`
    );
  };

  const getLocationByValue = (value) => {
    return citiesList.find(
      (location) => `${location.city}, ${location.country}` === value
    );
  }

  const handleFromSelect = (value) => {
    setError("")
    setSelectedFrom(value);
    featchTripsDatesData(selectedType, value, selectedTo)

    if (selectedFrom !== "") {
      setSelectedTo("")
    } else if (selectedTo !== "") {
      setLocationsFrom(defaultLocations)
      setLocationsTo(defaultLocations)
      return
    }

    const selectedFromLocation = getLocationByValue(value)
    if (selectedFromLocation) {
      const selectedCountry = selectedFromLocation.country;
      const filteredLocations = filterLocationsByCountry(selectedCountry);
      setLocationsTo(filteredLocations);
    }
  };

  const handleToSelect = (value) => {
    setError("")
    setSelectedTo(value);
    featchTripsDatesData(selectedType, selectedFrom, value)

    if (selectedTo !== "") {
      setSelectedFrom("");
    } else if (selectedFrom !== "") {
      setLocationsFrom(defaultLocations);
      setLocationsTo(defaultLocations);
      return
    }
    const selectedToLocation = getLocationByValue(value)
    if (selectedToLocation) {
      const selectedCountry = selectedToLocation.country;
      const filteredLocations = filterLocationsByCountry(selectedCountry);
      setLocationsFrom(filteredLocations);
    }
  };

  const handlePeopleSelect = (value) => {
    setSelectedPeople(value);
  };

  const handleChildrenSelect = (value) => {
    setSelectedChildren(value);
  };

  const handleOneWayDateChange = (date) => {
    setError("")
    setSelectedOneWayDate(date);
  };

  const handleTwoWayDateChange = (date) => {
    setError("")
    setSelectedTwoWayDate(date);
  };

  const handleSwitch = () => {
    if (selectedFrom && selectedTo) {

      setRotated(!isRotated);
      const temp = selectedFrom;
      setSelectedFrom(selectedTo);
      setSelectedTo(temp);

      featchTripsDatesData(selectedType, selectedTo, temp)

      setSelectedOneWayDate("")
      setSelectedTwoWayDate("")
    }
  };


  const handleSubmitSearch = () => {
    if (selectedFrom === "") {
      setError("Vă rugăm să alegeți locația de plecare.")
    } else if (selectedTo === "") {
      setError("Vă rugăm să alegeți locația de sosire.")
    } else if (selectedOneWayDate == "") {
      setError("Vă rugăm să alegeți data de plecare.")
    } else if (selectedType == "roundTrip" && selectedTwoWayDate == "") {
      setError("Vă rugăm să alegeți data de sosire.")
    } else if (selectedTwoWayDate !== "" && selectedOneWayDate.getTime() === selectedTwoWayDate.getTime()) {
      setError("Data de plecare și cea de sosire nu pot fi aceeași.")
    } else {
      setError("")
      setIsLoading(true)
      handlePriceData()
    }
  }

  function handlePriceData() {
    const departureId = getLocationByValue(selectedFrom).id;
    const toId = getLocationByValue(selectedTo).id;
    const data = {
      "type": selectedType,
      "departure": getLocationByValue(selectedFrom).id,
      "destination": getLocationByValue(selectedTo).id,
      "departureDate": formatDate(selectedOneWayDate),
      "returnDate": formatDate(selectedTwoWayDate),
      "adults": selectedPeople,
      "children": selectedChildren
    }

    dispatch(fetchPrice(data))
      .then(
        (response => {
          setIsLoading(false)
          navigate('/detalii-bilet', { state: { ticket: response.payload, departureId: departureId, toId: toId } })
        })
      )
      .catch((error) => {
        setIsLoading(false)
        setError("Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu.")
      });
  }

  function formatDate(inputDateString) {
    console.log(inputDateString)
    if (inputDateString != null && inputDateString != '') {
      const inputDate = new Date(inputDateString);

      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0');
      const year = inputDate.getFullYear();

      return `${day}-${month}-${year}`;
    } else {
      return '';
    }
  }


  function isDepartureDateDisabled(date) {
    const { departureAvailableWeekDay, departureUnavailableDates } = tripDatesData.data;
    const unavailableDates = departureUnavailableDates.map(dateWithTime => {
      const parts = dateWithTime.split('T');
      return parts[0];
    });
    const dayOfWeek = date.getDay();
    const convertedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    const currentDate = new Date();
    const formattedDate = format(date, 'yyyy-MM-dd');

    if (formattedDate < format(currentDate, 'yyyy-MM-dd')) {
      return false;
    }

    if (departureAvailableWeekDay.includes(convertedDayOfWeek) &&
      !unavailableDates.includes(formattedDate)) {
      return true;
    }

    return false;
  }

  function isDestinationDateDisabled(date) {
    const { returnAvailableWeekDay, returnUnavailableDates } = tripDatesData.data;
    const unavailableDates = returnUnavailableDates.map(dateWithTime => {
      const parts = dateWithTime.split('T');
      return parts[0];
    });
    const dayOfWeek = date.getDay();
    const convertedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    const formattedDate = format(date, 'yyyy-MM-dd');

    if (formattedDate < format(selectedOneWayDate, 'yyyy-MM-dd')) {
      return false;
    }

    if (returnAvailableWeekDay.includes(convertedDayOfWeek) &&
      !unavailableDates.includes(formattedDate)) {
      return true;
    }

    return false;
  }

  return (
    <div className="travel-container fade-in-anim">
      {error && (
        <div className="red-text">
          * {error}
        </div>
      )}
      <div className="travel-radio-group">
        <div>
          <input
            type="radio"
            id="oneWay"
            name="travel-option"
            value="oneWay"
            checked={selectedType === 'oneWay'}
            onChange={handleRadioChange}
          />
          <label for="oneWay">Dus</label>
        </div>
        <div>
          <input
            type="radio"
            id="roundTrip"
            name="travel-option"
            value="roundTrip"
            checked={selectedType === 'roundTrip'}
            onChange={handleRadioChange}
          />
          <label for="roundTrip">Dus-Intors</label>
        </div>
      </div>
      <div className="travel-card-grid">
        <div className="travel-item" ref={itemRef}>
          <div className="travel-item-top">
            <img src={locationPinIcon} />
            <p>DIN</p>
          </div>
          <div className="travel-dropdown">
            <DropdownLocations
              options={locationsFrom}
              selectedOption={selectedFrom}
              onSelect={handleFromSelect}
              placeholder={"Plecare"}
            />
          </div>
        </div>
        <div className="switch-button-container" style={{ left: maxOptionWidth }}>
          <button className="switch-button" onClick={handleSwitch}>
            <div className="switch-container">
              <img className="switch-container-icon" src={switchContainerIcon} />
              <img className={`switch-icon ${isRotated ? 'rotated' : ''}`} src={switchDestIcon} />
            </div>
          </button>
        </div>
        <div className="travel-item">
          <div className="travel-item-top">
            <img src={locationPinIcon} />
            <p>SPRE</p>
          </div>
          <div className="travel-dropdown">
            <DropdownLocations
              options={locationsTo}
              selectedOption={selectedTo}
              onSelect={handleToSelect}
              placeholder={"Sosire"}
            />
          </div>
        </div>

        <div className={`travel-item ${tripDatesData?.data?.departureUnavailableDates ? "" : "disabled"}`}>
          <div className="travel-item-top">
            <img src={calendarIcon} />
            <p>DUS</p>
          </div>
          <div className="travel-dropdown">
            <DatePicker
              selected={selectedOneWayDate}
              onChange={handleOneWayDateChange}
              dateFormat="dd.MM.yyyy"
              placeholderText={new Date().toLocaleDateString('ro-RO')}
              className="custom-datepicker"
              filterDate={isDepartureDateDisabled}
              onKeyDown={handleKeyDown}
              ref={datePickerRef}
            />
          </div>
        </div>

        <div className={`travel-item ${tripDatesData?.data?.returnUnavailableDates && selectedType === 'roundTrip' && selectedOneWayDate !== "" ? "" : "disabled"}`}>
          <div className="travel-item-top">
            <img src={calendarIcon} />
            <p>ÎNTORS</p>
          </div>
          <div className="travel-dropdown">
            <DatePicker
              selected={selectedTwoWayDate}
              onChange={handleTwoWayDateChange}
              dateFormat="dd.MM.yyyy"
              placeholderText={new Date().toLocaleDateString('ro-RO')}
              className="custom-datepicker"
              filterDate={isDestinationDateDisabled}
              onKeyDown={handleKeyDown}
              ref={datePickerRef}
            />
          </div>
        </div>
        <div className="travel-item">
          <div className="travel-item-top">
            <img src={peopleIcon} />
            <p>PASAGERI</p>
          </div>
          <div className="travel-seats">
            <div className="input-seats">
              <Dropdown
                options={seats}
                selectedOption={selectedPeople}
                onSelect={handlePeopleSelect}
                placeholder={"Adulți: 0"}
                prefix={"Adulți"}
              />
            </div>
            <div className="input-seats">
              <Dropdown
                options={seatsChild}
                selectedOption={selectedChildren}
                onSelect={handleChildrenSelect}
                placeholder={"Copii: 0"}
                prefix={"Copii"}
              />
            </div>
          </div>
        </div>
        
      </div>
      <div className='travel-child-info'style={{ width: maxOptionWidth }}>
          <p><strong>*</strong>Copiii până la <strong>5 ani</strong> beneficiază de <strong>50%</strong> reducere </p>
        </div>
      {isLoading ? (
        <div className="travel-search-loader" style={{ justifyContent: "center", display: "flex" }}>
          <Loader />
        </div>
      ) : (
        <button
          onClick={handleSubmitSearch}
          className="travel-search-btn">
          Caută →
        </button>
      )}

    </div>
  );
};

export default TravelComponent;