import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const fetchAvailableBus = createAsyncThunk(
  "fetchAvailableBus",
  async (busType) => {
    const response = await api.get("buses?busType=" + busType);
    return response.data;
  }
);
