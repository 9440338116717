import React from "react";
import phoneImg from "../assets/phone.svg";

const Phone = ({ phoneNumber }) => {
  const handlePhoneCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className="phone-body" onClick={handlePhoneCall} style={{cursor: "pointer"}}>
      <img src={phoneImg} alt="Icon" className="phone-image"/>
      <span className="phone-number">{phoneNumber}</span>
    </div>
  );
};
  
export default Phone;
