import ProgressBar from "../components/progres-bar-container";
import { useAppDispatch } from "../redux/store/store";
import { paymentConfirmation, generateInvoice } from "../redux/servicies/travel-service";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BuyTicketPage2 = () => {
  const [error, setError] = useState("");
  const [message, setMessage] = useState(
    "Vă rugăm așteptați. Se procesează plata dvs. ..."
  );
  const [showButton, setShowButton] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    paymentConfirmCall();
  }, [dispatch]);

  const paymentConfirmCall = () => {
    let orderId = queryParams.get("orderId")

    dispatch(paymentConfirmation(orderId))
      .unwrap()
      .then((data) => {
        setError("");
        if (data.status == "confirmed") {
          setMessage(
            "Plata dvs. a fost procesat cu succes. În cel mai scurt timp veți primi pe e-mail factura în format electronic."
          );
          generateInvoiceCall()
        } else {
          setMessage(
            "Atenție: În acest moment, plata dvs. nu poate fi procesată. Verificați dacă cardul este valid sau dacă aveți suficiente fonduri disponibile."
          );
        }

        setShowButton(true);
      })
      .catch((error) => {
        setError(
          "Ceva nu a mers bine. Vă rugăm să încercați din nou mai târziu."
        );
        console.log(error);
      });
  };

  const generateInvoiceCall = () => {
    let orderId = queryParams.get("orderId")
    let data = {
      "invoiceCode": orderId,
    }
    dispatch(generateInvoice(data))
  };



  const handleSubmit = () => {
    navigate(`/`);
  };

  return (
    <div className="buy-ticket-finish-container">
      <div className="progress-bar-finish-container">
        <ProgressBar isLastStep={false} isFinished={true} />
      </div>
      <div className="buy-ticket-finish-body">
        {error ? (
          <div className="red-text">{error}</div>
        ) : (
          <>
            {" "}
            <h3>Confirmare plata online</h3>
            <p>{message}</p>
            {showButton && (
              <button onClick={handleSubmit} className="send-button">
                Acasă →
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BuyTicketPage2;