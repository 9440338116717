import React from "react";
import { SocialMedia } from "../constants/general";

const SocialItems = () => (
  <div className="icon-container">
    <a
      href={SocialMedia.Facebook}
      target="_blank"
      rel="noopener noreferrer"
      className="icon-link"
    >
      <i className="fa fa-facebook icon"></i>
    </a>
    <a
      href={SocialMedia.Twitter}
      target="_blank"
      rel="noopener noreferrer"
      className="icon-link"
    >
      <i className="fa fa-twitter icon"></i>
    </a>
    <a href={SocialMedia.Gmail} className="icon-link">
      <i className="fa fa-envelope icon"></i>
    </a>
  </div>
);

export default SocialItems;
