import React from "react";

const PriceItem = ({ transport }) => {
    return (
        <div>
            <div className="price-item-container">
                <div className="text-container">
                    <span>{transport.route}</span>
                </div>
                <div className="text-container">
                    <span>{transport.price}<span>€</span></span>
                </div>
                <div className="text-container">
                    <span>{transport.returnPrice}<span>€</span></span>
                </div>
                <div className="text-container">
                    <span>{transport.roundTripPrice}<span>€</span></span>
                </div>
            </div>
        </div>
    );
};

export default PriceItem;