import { createSlice } from "@reduxjs/toolkit";
import { fetchAvailableParcels } from "../servicies/parcels-service";
import { dummyParcelsData } from "../../constants/data";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const allParcelsSlice = createSlice({
  name: "allParcelsReducer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableParcels.pending, (state) => {
        return { ...state, data: null, isLoading: true, error: null };
      })
      .addCase(fetchAvailableParcels.fulfilled, (state, action) => {
        return {
          ...state,
          data: action.payload,
          isLoading: false,
          error: null,
        };
      })
      .addCase(fetchAvailableParcels.rejected, (state, action) => {
        console.log("rejected")
        return {
          ...state,
          data: dummyParcelsData,
          isLoading: false,
          error: null,
        };
      });
  },
});

export default allParcelsSlice.reducer;