import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Calendar from "../components/custom-calendar";
import PriceHeader from "../components/price-header";
import PriceItem from "../components/price-item";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { fetchDestinationDetails } from "../redux/servicies/travel-service";
import Loader from "../components/loader";

const Destination = () => {
    const url = window.location.href;
    const urlParams = new URL(url);
    const location = useLocation();
    const countryId = location.state.id
    const countryName = urlParams.searchParams.get('name');
    const { data, isLoading, error } = useAppSelector((state) => state.allDestinationDetailsReducer);

    const dispatch = useAppDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchDestinationDetails(countryId));
    }, [dispatch]);

    return (
        <div className="destination-container">
            <div className="destination-body">
                <h2>Informații despre cursele <span className="destination-text">România -</span> <span className="destination-text">{countryName}  📞 {data?.phone}</span> </h2>
                {isLoading && <div style={{ justifyContent: "center", display: 'flex' }}><Loader /></div>}
                {error && <div style={{ color: "red", justifyContent: "center", display: 'flex' }}>Datele nu au putut fi încărcate. Vă rugăm să încercați din nou mai târziu</div>}
                {data && !isLoading && !error && (<>
                    <p>{data?.description}</p>
                    <div className="calendars-container">
                        <div className="calendar-body">
                            <h2>Program plecări </h2>
                            <Calendar selectedDays={data.departureDates}
                                excludedDays={[]}
                            />
                        </div>

                        <div className="calendar-body">
                            <h2>Program retururi </h2>
                            <Calendar selectedDays={data.returnDates}
                                excludedDays={[]}
                            />
                        </div>
                    </div>
                    <h2>Preț bilete <span className="destination-text">România -</span> <span className="destination-text">{countryName}</span> </h2>
                    <PriceHeader />
                    <div className="prices-lis">
                        {
                            data.destinationCities.map((item) =>
                                <div>
                                    <PriceItem
                                        transport={item}
                                    />
                                </div>
                            )
                        }
                    </div>
                </>)}
            </div>
        </div>
    );
};

export default Destination;
