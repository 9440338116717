import React from "react";
import location from "../assets/locationInfo.svg";
import phone from "../assets/phoneInfo.svg";
import email from "../assets/mailInfo.svg";
import SocialItems from "../components/social";
import Phone from "./phone";

const ContactInfo = () => (
    <div className="contact-info-card">
        <div className="title-container">
            <span className="title-info">
                S.C. TRANSPORT AUTO SEVERIN S.A
            </span>
        </div>
        <div>
            <span className="other-info">
                CUI: <span>
                    RO742093
                </span>
            </span>
        </div>
        <div>
            <span className="other-info">
                 REG. COM: <span>
                    J33/39/1991.
                </span>
            </span>
        </div>
        <hr className="line"></hr>
        <div className="info-container">
            <img src={location} alt="Icon" className="info-image" />
            <span className="info-text">
                VATRA DORNEI, Calea Transilvaniei, nr. 135
            </span>
        </div>
        <div className="info-container">
            {/* <img src={phone} alt="Icon" className="info-image" /> */}
            <span className="info-text">
            <br></br>
            <div className="contact-numbers">
            <Phone phoneNumber={"0741258455"} />
              <br></br>
              <Phone phoneNumber={"0724122272"} />
              <br></br>
              <Phone phoneNumber={"0741258456"} />
              <br></br>
              <Phone phoneNumber={"0756086166"} />
            </div>
            <br></br>
            </span>
        </div>
        <div className="info-container">
            <img src={email} alt="Icon" className="info-image" />
            <span className="info-text">
                contact@euro-fratello.ro
            </span>
        </div>
        <hr className="line"></hr>
        <h2>
            Agenții
        </h2>
        <div>
        <a className="btn-link" href="/agentii-romania">
            Agenții România
        </a>
        </div>
        <div>
        <a className="btn-link" href="/agentii-internationale">
            Agenții Internaționale
        </a>
        </div>
        <hr className="line"></hr>
        <h2>
            Urmărește-ne pe rețelele sociale
        </h2>
        <div>
            <SocialItems />
        </div>

    </div>
);

export default ContactInfo;
