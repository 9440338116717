import { useEffect } from "react";
import NewsCardMain from "../components/news-main-card";
import { useAppDispatch, useAppSelector } from "../redux/store/store";
import { fetchAvailableNews } from "../redux/servicies/news-service";
import Loader from "../components/loader";

const News = () => {
  const { data, isLoading, error } = useAppSelector(
    (state) => state.allNewsReducer
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAvailableNews());
  }, [dispatch]);

  return (
    <div className="news-main-container">
      <div className="news-main-body">
        <h2>Ultimile Articole și Blog</h2>
        <p>
          Sursa ta de informații interne pentru sfaturi privind transportul în
          grup, conținut de călătorie de experți și multe altele.
        </p>
        {isLoading && (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Loader />
          </div>
        )}
        {error && (
          <div
            style={{ color: "red", justifyContent: "center", display: "flex" }}
          >
            Datele nu au putut fi încărcate. Vă rugăm să încercați din nou mai
            târziu
          </div>
        )}
        {!isLoading && !error && (
          <div className="news-card-grid-main">
            {data && Array.isArray(data) && (
              [...data]
                .sort((a, b) => new Date(b.publishDate) - new Date(a.publishDate)) // Sortare descrescătoare
                .map((n) => (
                  <NewsCardMain
                    key={n.id} // Asigurați-vă că furnizați un cheie unică pentru fiecare componentă
                    image={n.image}
                    title={n.title}
                    description={n.content}
                    tag={n.tags}
                    readMoreLink={`/detalii-noutati?id=${n.id}`}
                  />
                ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
